import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    /**
     * 保险机构
     */
    // 机构列表
    async postInsuranceInstituteList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bx/organization/list", params);
      return data;
    },
    // 机构创建
    async postInsuranceInstituteAdd({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bx/organization/edit", params);
      return data;
    },
    // 返点创建
    async postInsuranceInstituteRebatesAdd({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bx/rebates/edit", params);
      return data;
    },
    // 返点列表
    async postInsuranceInstituteRebatesList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bx/rebates/list", params);
      return data;
    },

    /**
     * 保险套餐
     * @param rootState
     * @param params
     */
    // 套餐列表
    async getInsurancePackageList({ rootState }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/op/bxPackage/pageList", params);
      return data;
    },
    // 套餐详情
    async getInsurancePackageDetail({ rootState }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/op/bxPackage/detail/" + params.packageNo, params);
      return data;
    },
    // 套餐保存
    async postInsurancePackageSave({ rootState }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bxPackage/save", params);
      return data;
    },
    // 套餐修改状态
    async postInsurancePackageChangeStatus({ rootState }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bxPackage/changeStatus", params);
      return data;
    },
    // 套餐删除
    async deleteInsurancePackageRemove({ rootState }, params) {
      params.method = "delete";
      const data = await rootState.http(VUE_APP_URL_API + "/op/bxPackage/remove/" + params.packageNo, params);
      return data;
    },
    // 发放记录
    async getInsurancePackageIssueList({ rootState }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/op/bxPackage/issueList", params);
      return data;
    },

    /**
     * 保险模版
     */
    // 模版详情
    async getInsuranceTemplateInfo({ rootState }, params) {
      params.method = "get";
      let { categoryCode } = params;
      const data = await rootState.http(VUE_APP_URL_API + `/op/bxTemplate/detail/byCategory/${categoryCode}`, params);
      return data;
    },
    // 模版保存
    async postInsuranceTemplateSave({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bxTemplate/save", params);
      return data;
    },
    // 获取所有险种
    async getInsuranceAll({ rootState }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + `/op/bxCategory/allCategory`, params);
      return data;
    },

    async getBxInquiryList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bx/inquiry/list", params);
      return data;
    },
    async getBxInquiryDetail({ rootState }, params = {}) {
      console.log(params);
      const data = await rootState.http(VUE_APP_URL_API + "/op/bx/inquiry/detail/" + params, params);
      return data;
    },
    async confirmPayment({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bx/inquiry/confirmPayment", params);
      return data;
    },
    async editVehicleLicenseInfo({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bx/inquiry/editVehicleLicenseInfo", params);
      return data;
    },
    async editOwnerInfo({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bx/inquiry/editOwnerInfo", params);
      return data;
    },
    async editNonOwnerInfo({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bx/inquiry/editOwnerInfo", params);
      return data;
    },
    async inquiryRejected({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bx/inquiry/rejected", params);
      return data;
    },
    async getGivePackages({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bx/inquiry/give/packages", params);
      return data;
    },
    async agree({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bx/inquiry/agree", params);
      return data;
    },
    async paymentLink({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bx/inquiry/paymentLink", params);
      return data;
    },

    async getLoggerList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bx/inquiry/logger/list", params);
      return data;
    },

    async returnElectronicPolicy({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bx/electronic/policy/return", params);
      return data;
    },

    async getElectronicPolicyList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bx/electronic/policy/list", params);
      return data;
    },

    async quotationReturn({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bx/inquiry/quotationReturn", params);
      return data;
    },

    async editQualityCertInfo({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/bx/inquiry/editQualityCertInfo", params);
      return data;
    },

    /**
     * 险种管理
     */
    // 获取险种列表
    async getInsuranceTypesList({ rootState }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + `/op/bxCategory/pageList`, params);
      return data;
    },
    // 险种保存
    async postInsuranceTypesSave({ rootState }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/op/bxCategory/save`, params);
      return data;
    },
    // 险种删除
    async postInsuranceTypesDelete({ rootState }, params) {
      params.method = "delete";
      let { categoryCode } = params;
      const data = await rootState.http(VUE_APP_URL_API + `/op/bxCategory/remove/${categoryCode}`, params);
      return data;
    },

    /**
     * 代理事件
     */
    // 代理事件列表
    async getAgentEventList({ rootState }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + `/op/agent/event/pageList`, params);
      return data;
    },
    // 代理事件详情
    async getAgentEventDetail({ rootState }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/op/agent/event/detail/"+params.eventNo, params);
      return data;
    },
    // 代理事件保存
    async saveAgentEvent({ rootState }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/op/agent/event/save`, params);
      return data;
    },
    // 代理事件修改状态
    async agentEventChangeStatus({ rootState }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/op/agent/event/changeStatus`, params);
      return data;
    },
    // 所有代理事件
    async allAgentEventList({ rootState }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + `/op/agent/event/all/codeMap`, params);
      return data;
    },

    /**
     * 代理任务
     */
    // 代理任务列表
    async getAgentTaskList({ rootState }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + `/op/agent/task/pageList`, params);
      return data;
    },
    // 代理任务详情
    async getAgentTaskDetail({ rootState }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/op/agent/task/detail/"+params.taskNo, params);
      return data;
    },
    // 代理任务保存
    async saveAgentTask({ rootState }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/op/agent/task/save`, params);
      return data;
    },
    // 代理任务修改状态
    async agentTaskChangeStatus({ rootState }, params) {
      const data = await rootState.http(VUE_APP_URL_API + `/op/agent/task/changeStatus`, params);
      return data;
    }
  }
};

export default store;
