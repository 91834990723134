import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const app = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getvehicleTree({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/vehicleModel/queryModelTree", params);
      return data;
    },
    async getAreaList({ rootState, commit }, params = { level: 1 }) {
      const data = await rootState.http(VUE_APP_URL_API + "/area/queryAreaCode", params);
      return data;
    },
    async getDictionaryList({ rootState, commit }, dictionaryCode = "") {
      const data = await rootState.http(VUE_APP_URL_API + "/basic/queryDictionaryList", {
        dataCode: dictionaryCode
      });
      if (data.data && data.data.list) {
        return data.data.list.map(item => {
          return Object.assign(item, {
            label: item.dataDesc,
            value: item.dataValue
          });
        });
      } else {
        return [];
      }
    },
    async getVehicleInfoByVin({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/vehicleModel/getVehicleListByVin", params, {
        errorMessage: false
      });
      return data;
    },
    async getSystemValue({ rootState, commit }, name = "") {
      const data = await rootState.http(VUE_APP_URL_API + "/basic/getDataValue", {
        dataCode: name
      });
      return data;
    },
    async updateSystemValue({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/basic/saveDataValue", params);
      return data;
    },
    async getUploadToken({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/filecloud/getOssToken", params);
      return data;
    },
    async getNewOrderMessage({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/external/order/message/newOrder", params);
      return data;
    },
    async getOssAccessUrl({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/getOssAccessUrl", params);
      return data;
    }
  }
};

export default app;
