import router from "../../router/index";
import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const staff = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getPartsInventoryList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/inventory/queryList", params);
      return data;
    },
    async saveInWarehouse({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/inbound/saveInbound", params);
      return data;
    },
    async getInventoryListByParts({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/inventory/queryListByParts", params);
      return data;
    },
    async getInWarehouseList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/inbound/queryList", params);
      return data;
    },
    async getInWarehouseDetail({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/inbound/getInbound", params);
      return data;
    },
    async inWarehouseComplete({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/inbound/completed", params);
      return data;
    },
    async inWarehouseCancel({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/inbound/cancel", params);
      return data;
    },
    async saveOutWarehouse({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/outbound/saveOutbound", params);
      return data;
    },
    async getOutWarehouseList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/outbound/queryList", params);
      return data;
    },
    async getOutWarehouseDetail({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/outbound/getOutbound", params);
      return data;
    },
    async outWarehouseComplete({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/outbound/completed", params);
      return data;
    },
    async outWarehouseCancel({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/outbound/cancel", params);
      return data;
    },
    async getInventoryRecord({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/inventory/queryInventoryDetail", params);
      return data;
    },
    async saleTypeSaveOutbound({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/outbound/saveDetail", params);
      return data;
    },
    async queryPartsInventoryOnBatch({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/inventory/queryInventoryByPartsCodeArray", params);
      return data;
    }
  }
};

export default staff;
