import { query } from "express";
import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async queryAllChannel({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/store/queryAllChannel", params);
      return data;
    },
    async openChannel({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/store/openChannel", params);
      return data;
    },
    async getSendRecords({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opUserAccount/tempkey/querySendRecords", params);
      return data;
    },
    async sentApply({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opUserAccount/tempkey/send", params);
      return data;
    },
    async syncStatusApi({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opParts/sync", params);
      return data;
    },
    async saveTenant({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/tenant/saveTenant", params);
      return data;
    },
    async getSettleMethodList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/supplier/getBalanceWay", params);
      return data;
    },
    async getTenantList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/tenant/queryTenantList", params);
      return data;
    },
    async getTenantDetail({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/tenant/getTenant", params);
      return data;
    },
    async queryApplyListV2({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/storeApply/queryStoreApplyList/v2", params);
      return data;
    },
    async getStoreInfo({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/store/getOpStore", params);
      return data;
    },
    async getApplyInfo({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/storeApply/getStoreApply", params);
      return data;
    },
    async createStore({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/storeApply/createStore", params);
      return data;
    },
    async createStoreByTmall({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/storeApply/openTmall", params);
      return data;
    },
    async getStoreList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/store/queryOpStore", params);
      return data;
    },
    async updateEnableStore({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/store/enableStore", params);
      return data;
    },
    async updateDisableStore({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/store/disableStore", params);
      return data;
    },
    async queryStoreCode({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/store/exist", params, {
        errorMessage: false
      });
      return data;
    },
    async updateSignupStatus({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/storeApply/signedStore", params);
      return data;
    },
    async updateTmallCheck({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/storeApply/tmallVerify", params);
      return data;
    },
    async getViewContractUrl({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/storeApply/viewContract", params);
      return data;
    },
    async updateStoreApply({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/storeApply/modifyStoreApply", params);
      return data;
    },
    async applyResign({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/storeApply/eSignResign", params);
      return data;
    },
    async cancelSign({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/storeApply/eSignRevoke", params);
      return data;
    },
    async resendPhoneMessage({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/store/resendInitStoreSms", params);
      return data;
    },
    async createStoreNotGiti({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/store/createStore", params);
      return data;
    },
    async queryTmallChangeList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opTmallInfo/queryTmallInfoModifyRecordPage", params);
      return data;
    },
    async queryTmallChangeDiffDetail({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opTmallInfo/getTmallInfoModifyRecord", params);
      return data;
    },
    async updateTmallChangeCheckResult({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opTmallInfo/auditTmallInfo", params);
      return data;
    },
    async queyrStoreContactList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/esign/opContract/queryContractList", params);
      return data;
    },
    async queryViewContractUrlV2({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/store/viewContract/v2", params);
      return data;
    },
    async insertRenewSend({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/storeApply/issueRenewalContract", params);
      return data;
    },
    async queryPrevRenewFailureList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/storeApply/queryLastIssueFailureRecord", params);
      return data;
    },
    async queryContractTmepList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/esign/contract/queryTemplateList", params);
      return data;
    },
    async insertTmallUrge({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/storeApply/eSignRushsign", params);
      return data;
    },
    async closeTmall({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/storeApply/closeTmall", params);
      return data;
    },
    async getServiceItem({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/store/queryStoreEnableServiceItemTemplate", params);
      return data;
    },
    saveStoreServiceItem({ rootState }, params = {}) {
      const data = rootState.http(VUE_APP_URL_API + "/op/store/saveStoreEnableServiceItem", params);
      return data;
    },
    async getCashierSubjectList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/store/cashier/subject/list", params);
      return data;
    },
    async saveCashierSubject({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/store/cashier/subject/save", params);
      return data;
    },
    async getCashierSubject({ rootState, commit }, storeCode: string) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/store/" + storeCode + "/cashierSubject", {});
      return data;
    },
    // 获取基础数据报表
    async getBasicsDataList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/report/storeBase/getStoreReportPageData", params);
      return data;
    },
    // 获取优惠券列表
    async getStoreCouponList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opCoupon/activity/store/queryList", params);
      return data;
    },
    // 组合营销保存
    async postPackageSave({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/promo/package/save", params);
      return data;
    },
    // 组合营销列表
    async getPackageList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/promo/package/list", params);
      return data;
    },
    // 获取营销套餐详细
    async getPackageInfo({ rootState, commit }, packageCode: string) {
      const data = await rootState.http(VUE_APP_URL_API + "/promo/package/detail/" + packageCode, {});
      return data;
    },
    // 修改营销套餐状态
    async updatePackageState({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/promo/package/operate", params);
      return data;
    },
    // 获取用户购买记录
    async getPromoPackageUser({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/promo/package/user/page", params);
      return data;
    },

    async getAllStoreList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/store/searchStoreList", params);
      commit("setStoreList", data.data.list || []);
      return data;
    },
    async updateStore({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/store/updateStore", params);
      return data;
    },
    // 引流活动-获取列表 flowActivity
    async getFlowActivityList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/promotion/drainageActivity/pageList", params);
      return data;
    },
    // 引流活动-获取数据列表
    async getFlowActivityDataList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/promotion/drainageActivity/order/pageList", params);
      return data;
    },
    // 引流活动-活动保存
    async flowActivitySave({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/promotion/drainageActivity/save", params);
      return data;
    },
    // 引流活动-获取活动详情
    async getFlowActivityDetail({ rootState }, params) {
      const data = await rootState.http(
        VUE_APP_URL_API + `/promotion/drainageActivity/${params.activityCode}/detail`,
        params
      );
      return data;
    },
    // 引流活动-活动保存
    async setFlowActivityState({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/promotion/drainageActivity/changeState", params);
      return data;
    },

    // 人群管理-获取标签
    async crowdManagementTag({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/summary/queryAllTagCondition", params);
      return data;
    },
    // 人群管理-获取人群池列表
    async crowdManagementList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/summary/pageCrowdList", params);
      return data;
    },
    // 人群管理-保存
    async crowdManagementSave({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/summary/createCrowd", params);
      return data;
    },
    // 人群管理-删除人群池
    async crowdManagementDelete({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/summary/deleteCrowd", params);
      return data;
    },
    // 人群管理-计算覆盖人数
    async crowdManagementCount({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/summary/calculateCoverNum", params);
      return data;
    },
    // 人群管理-人群洞察
    async crowdManagementChart({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/summary/queryCrowdData", params);
      return data;
    },
    // 人群管理-更新用户数据
    async crowdManagementUpdata({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/summary/asyncSummaryUserHierarchy", params);
      return data;
    },
    // 人群管理-更新用户数据
    async crowdManagementCityList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/area/cityList", params);
      return data;
    },
    // 人群管理-重新计算
    async againCalculateCoverNum({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/summary/againCalculateCoverNum", params);
      return data;
    },

    /**
     * 素材库管理
     */
    // 获取所有渠道
    async getContentChannelList({ rootState }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/cms/location/channel/list", params);
      return data;
    },
    // 获取所有坑位
    async getLocationList({ rootState }, params) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/location/locationNameList", params);
      return data;
    },
    // 获取所有页面
    async getContentPageList({ rootState }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/cms/location/type/list", params);
      return data;
    },
    // 获取坑位
    async contentModuleLocation({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/location/list", params);
      return data;
    },
    // 内容管理
    // 新增内容
    async contentModuleAdd({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/content/add", params);
      return data;
    },
    // 修改内容
    async contentModuleEdit({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/content/update", params);
      return data;
    },
    // 内容列表
    async contentModuleList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/content/page", params);
      return data;
    },
    // 修改状态
    async contentModuleStatus({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/content/updateStatus", params);
      return data;
    },
    // 内容删除
    async contentModuleDelete({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/content/delete", params);
      return data;
    },
    // 内容详情
    async contentModuleDetail({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/content/info", params);
      return data;
    },
    // 渠道&坑位管理
    // 新建子分类
    async channelPitAdd({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/location/addChild", params);
      return data;
    },
    // 修改子分类
    async channelPitEdit({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/location/update", params);
      return data;
    },
    // 渠道&坑位列表
    async channelPitList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/location/page", params);
      return data;
    },
    // 渠道&坑位详情
    async channelPitDetail({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/location/info", params);
      return data;
    },
    // 渠道&坑位状态开关
    async channelPitStatus({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/location/updateStatus", params);
      return data;
    },
    // 渠道&坑位删除
    async channelPitDelete({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/location/delete", params);
      return data;
    },
    // 内容分发管理
    // 新建分发
    async distributeAdd({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/distribute/save", params);
      return data;
    },
    // 分发列表
    async distributeList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/distribute/pageList", params);
      return data;
    },
    // 分发详情
    async distributeDetail({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/distribute/detail", params);
      return data;
    },
    // 分发修改状态
    async distributeStatus({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/distribute/changeStatus", params);
      return data;
    },
    // 分发排序
    async distributeSort({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/distribute/changeSort", params);
      return data;
    },
    // 分发删除
    async distributeDelete({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/cms/distribute/delete", params);
      return data;
    },

    /**
     * 拼团管理
     */
    // 拼团列表
    async groupActivityList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/group/buying/activity/page", params);
      return data;
    },
    // 删除拼团
    async groupActivityDel({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/group/buying/activity/delete", params);
      return data;
    },
    // 结束拼团
    async groupActivityEnd({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/group/buying/activity/end", params);
      return data;
    },
    // 拼团活动详情
    async groupActivityDetail({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/group/buying/activity/info", params);
      return data;
    },
    // 新增or修改拼团
    async groupActivityAdd({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/group/buying/activity/add", params);
      return data;
    },

    // 拼团记录列表
    async groupOrderList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/group/buying/activity/team/page", params);
      return data;
    },
    // 拼团记录详情
    async groupOrderDetail({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/group/buying/activity/team/detail", params);
      return data;
    },
    // 拼团立即成团
    async rightTeamSuccess({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/group/buying/activity/team/rightTeamSuccess", params);
      return data;
    }
  }
};

export default store;
