import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getServiceList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opServiceItem/queryServiceItemList", params);
      return data;
    },
    async updateServiceItemStatus({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opServiceItem/updateServiceItemStatus", params);
      return data;
    },
    async createService({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/service/item/createServiceItem", params);
      return data;
    },
    async getServiceDetail({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/service/item/getServiceItem", params);
      return data;
    },
    async updateService({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/service/item/updateServiceItem", params);
      return data;
    },
    async queryTwoLevelClassList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/service/item/queryCodeByLikeName", params);
      return data;
    },
    async getServiceItemList({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opServiceItem/queryServiceItemList", params);
      return data;
    },
    async createServiceItem({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opServiceItem/createServiceItem", params);
      return data;
    },
    async updateServiceItem({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opServiceItem/updateServiceItem", params);
      return data;
    },
    async getopserviceitem({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opServiceItem/getServiceItem", params);
      return data;
    },
    async getAssignStore({ rootState, commit }, params) {
      params.method = "get";
      const data = await rootState.http(VUE_APP_URL_API + "/opServiceItem/queryAssignStore", params);
      return data;
    },
    async saveAssignStore({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opServiceItem/assignStore", params);
      return data;
    },
    async setServiceSort({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opServiceItem/sort", params);
      return data;
    },
    async batchAssignStore({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opServiceItem/batchAssignStore", params);
      return data;
    },
    async batchCancelAssignStore({ rootState, commit }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opServiceItem/batchCancelAssignStore", params);
      return data;
    }
  }
};

export default store;
