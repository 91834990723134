import { getTestPort } from "@/utils/common";
const API_FACTORY = process.env.VUE_APP_FACTORY_API;

const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {
    onAccountManageRow: {},
    currentTabTypeAccount: "payment-customer"
  },
  getters: {},
  mutations: {
    setOnAccountManageRow(state, comState) {
      state.onAccountManageRow = comState;
    },
    setAccountCurrentTabType(state, data) {
      state.currentTabTypeAccount = data;
    }
  },
  actions: {
    // async queryIncomeExpenseMethodList({ rootState }, params = {}) {
    //   const data = await rootState.http(VUE_APP_URL_API + "/report/queryIncomeExpenseMethodList", params);
    //   return data;
    // },
    // async statisticOnAccount({ rootState }, params = {}) {
    //   const data = await rootState.http(VUE_APP_URL_API + "/order/statisticOnAccount", params);
    //   return data;
    // },
    // async getCustomerUnpaidOrder({ rootState }, params = {}) {
    //   const data = await rootState.http(VUE_APP_URL_API + "/order/getCustomerUnpaidOrder", params);
    //   return data;
    // },
    // async queryOnAccountCustomer({ rootState }, params = {}) {
    //   const data = await rootState.http(VUE_APP_URL_API + "/order/queryOnAccountCustomer", params);
    //   return data;
    // },
    async getReceiptDetail({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opReceipt/getReceipt", params);
      return data;
    },
    async getReceiptList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/opReceipt/queryReceiptList", params);
      return data;
    },
    // async createGeneralReceipt({ rootState }, params = {}) {
    //   const data = await rootState.http(VUE_APP_URL_API + "/receipt/createOrderReceipt", params);
    //   return data;
    // },
    // async createOtherReceipt({ rootState }, params = {}) {
    //   const data = await rootState.http(VUE_APP_URL_API + "/receipt/createOtherReceipt", params);
    //   return data;
    // },
    /*async getPaymentDetail({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/payment/getPayment", params);
      return data;
    },
    async getPaymentList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/payment/queryPaymentList", params);
      return data;
    },
    async createOtherPayment({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/payment/createPaymentOther", params);
      return data;
    },
    async getBillList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/tmallbill/queryBillList", params);
      return data;
    },
    async getBillDetail({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/tmallbill/getBillDetail", params);
      return data;
    },
    async confirmBill({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/tmallbill/confirmBill", params);
      return data;
    },
    async confirmBillReceived({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/tmallbill/receiveBill", params);
      return data;
    },
    async getInvoiceList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/invoice/queryInvoiceList", params);
      return data;
    },
    async getGrossProfitList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/report/queryGrossProfit", params);
      return data;
    },
    async getGrossProfitReport({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/report/statisticTrendGrossProfit", params);
      return data;
    },
    async getItemProductionValue({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/report/queryItemProductionValue", params);
      return data;
    },
    async getIncomeExpenseReport({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/report/incomeExpenseStatisticTrend", params);
      return data;
    },
    async getIncomeExpenseSum({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/report/queryIncomeExpenseResult", params);
      return data;
    },
    async queryPurchaseBillList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/purchasebill/queryBillList", params);
      return data;
    },
    async savePurchaseBill({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/purchasebill/savePurchaseBill", params);
      return data;
    },
    async queryPurchaseBillDetail({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/purchasebill/getBillDetail", params);
      return data;
    },
    async createPurchaseBillSettlement({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/purchasebill/settlePurchaseBill", params);
      return data;
    },
    async createPurchaseBillInvoice({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/purchasebill/invoicePurchaseBill", params);
      return data;
    },
    // 门店 - 查询技师产能报表
    async getStoreTechnicianReports({ rootState }, params = {}) {
      const data = await rootState.httpCarService(API_FACTORY + "/saas/order/getStoreTechnicianReports", params);
      return data;
    },
    // 门店 - 查询技师产能报表导出
    async exportStoreTechnicianReport({ rootState }, params = {}) {
      const data = await rootState.httpCarService(API_FACTORY + "/saas/order/exportStoreTechnicianReport", params);
      return data;
    },
    // 门店 - 查询技师产能饼图统计
    async getStoreTechnicianStatic({ rootState }, params = {}) {
      const data = await rootState.httpCarService(API_FACTORY + "/saas/order/getStoreTechnicianStatic", params);
      return data;
    }*/


    async getFinanceWithdrawalList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/fund/withdrawal/list", params);
      return data;
    },
    async getFundSummary({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/fund/summary", params);
      return data;
    },

    async platformRecharge({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/fund/platform/virtualAccount/recharge", params);
      return data;
    },

    async queryPlatformRechargeList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/fund/platform/virtualAccount/recharge/list", params);
      return data;
    },
  }
};

export default store;
